import Head from "next/head"
import Link from "next/link"
import React, { ReactNode } from "react"
import {useTheme} from "next-themes";
import { ChevronDownIcon, LogOutIcon, MoonIcon, SunIcon, User2Icon } from "lucide-react";
import { Avatar, Badge, Button, Chip, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from "@nextui-org/react";
import {
  Navbar, 
  NavbarBrand, 
  NavbarContent, 
  NavbarItem, 
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from "@nextui-org/react";
import { PageBase } from "../../pages/_app";
import { useRouter } from "next/router";
import { IUser } from "../../db/models";
import { Roles } from "../utils/session";
import { motion } from 'framer-motion';


const layoutVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};


export default function Layout({ children, user }: {children: ReactNode[] | ReactNode , user?: IUser | null}) {
  return (
    <>
      <NavBar user={user}/>
      <motion.main
        initial="initial"
        animate="animate"
        exit="exit"
        variants={layoutVariants}
        className="min-w-full min-h-screen p-8"
      >
        {children}
      </motion.main>
      <footer className='flex flex-col items-center justify-center min-w-full mt-8'>
        {/* <nav className="flex items-center justify-center list-none">
          <li><a href="/terms" className='mx-4'>شرایط و قوانین</a></li>
          <li><a href="/contact" className='mx-4'>تماس با ما</a></li>
          <li><a href="/support" className='mx-4'>پشتیبانی</a></li>
          <li><a href="/contact" className='mx-4'>ارسال بازخورد</a></li>
        </nav> */}
        <a href='mailto:support@atlier.ir' className="my-3">support@atabaat.co</a>
        <small className='text-xs mt-2'>2023</small>
      </footer>
    </>
  )
}

const menus = {
   'chief': [
  {
    title: 'درخواست‌ها',
    path: '/request-list'
  },
  {
    title: 'سهمیه‌ها',
    path: '/grant-list'
  },
  {
    title: 'گزارشات',
    path: '/report-list'
  },
  {
    title: 'هتل‌ها',
    path: '/hotel-list'
  },
  {
    title: 'پکیج‌ها',
    path: '/package-list'
  },
  {
    title: 'کاربران',
    path: '/user-list'
  },
  {
    title: 'آژانس‌ها',
    path: '/agency-list'
  },
  {
    title: 'شرکت‌های حمل عراقی',
    path: '/tcompany-list'
  },
],
'admin': [
  {
    title: 'التقارير',
    path: '/report-list'
  },
  {
    title: 'الرحلات الحالية',
    path: '/ongoing-list'
  },
],
'manager' :[
  {
    title: 'ساخت کاروان',
    path: '/safar-new'
  },
  {
    title: 'گزارشات',
    path: '/report-list'
  },
  {
    title: 'درخواست‌ها',
    path: '/request-list'
  },
  {
    title: 'پکیج‌ها',
    path: '/package-list'
  },
  {
    title: 'سفرهای جاری',
    path: '/ongoing-list'
  },
]
};

const NavBar = ({user}: PageBase) => {
  const { theme, setTheme } = useTheme()
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const { pathname } = useRouter();
  
  return (
    <Navbar onMenuOpenChange={setIsMenuOpen} dir="" isBordered >
      <NavbarContent>
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <Link href='/'>
          <NavbarBrand className="flex flex-col items-start">
            <span className="font-bold text-inherit -mb-2 mr-3">العتبات</span>
            <span className="text-xs">للسفر و السیاحیة</span>
          </NavbarBrand>
        </Link>
      </NavbarContent>

      {user?.role && 
        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          { menus[user?.role as Roles].map((m) => (
            <NavbarItem key={m.path}>
              <Link href={m.path} className={pathname === m.path? 'font-bold': ''}>
                • {m.title}
              </Link>
            </NavbarItem>
          ))}
        </NavbarContent>
      }
      <NavbarContent justify="end">
      {!user? <>
        <Link href="/login">
          <Button color="primary" variant="flat">
            ورود
          </Button>
        </Link>
      </>:
      <>
        <Dropdown>
          <NavbarItem>
            <DropdownTrigger>
              <Avatar size="md" className="cursor-pointer"/>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu
            aria-label="Profile"
            itemClasses={{
              base: "gap-4",
            }}
          >
            <DropdownItem
              key="user"
              description=""
              startContent={<User2Icon className='' />}
            >
              {user.email}
            </DropdownItem>
            <DropdownItem
              key="theme"
              startContent={[
                (theme === 'dark') && <SunIcon />,
                (theme === 'light') && <MoonIcon />
              ]}
              onClick={() => setTheme((theme === 'light')? 'dark': 'light')}
            >
              <div className="flex items-center">
                تغییر حالت روز / شب
              </div>
            </DropdownItem>
            <DropdownItem
              key="logout"
              description=""
              startContent={<LogOutIcon className='-scale-100' />}
            >
              خروج از سیستم
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </>}
      </NavbarContent>
      <NavbarMenu>
        { menus[user?.role as Roles]?.map?.((m) => (
          <NavbarMenuItem key={m.path}>
            <Link href={m.path} className={pathname === m.path? 'font-bold': ''}>
              • {m.title}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
      
    </Navbar>
  )
}