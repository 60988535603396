
import type { NextPage } from 'next'
import Head from 'next/head'
import { ChangeEvent, useCallback, useEffect, useId, useRef, useState } from 'react'
import Layout from '../src/components/layout'
import Script from 'next/script'
import Link from 'next/link'
import { Button } from '@nextui-org/react'
import { IUser } from '../db/models'
import Image from 'next/image'
import BaseImage from '../public/base.png'

function Home ({ user }: {user: IUser}) {
  
  return (
    <div className="flex min-h-screen flex-col items-center pb-2">
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <Script
          defer
          src='https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js'
        />
      </Head>
      <Layout user={user}>
        <div className='flex flex-col justify-center items-center gap-4'>
          <Image src={BaseImage} alt='base'/>
            سامانه هماهنگی حمل نقل کاروان
            <Link href='/login'><Button className='mt-4 px-4' color='primary' variant='flat'>ورود به سیستم</Button></Link>
        </div>
      </Layout>
    </div>
  )
}

export default Home;

